import { CSSProperties } from 'react';

export const isDefined = (value: any) => value !== undefined && value !== null;
export const isNotDefined = (value: any) => !isDefined(value);

// Helps to create style objects that supports IntelliSense for passed styles object keys
// Inspired from `Stylesheet.create` method from React Native
type NamedStyles<T> = { [P in keyof T]: CSSProperties };
export function createStyles<T extends NamedStyles<T> | NamedStyles<any>>(
  // The extra & NamedStyles<any> here helps Typescript catch typos: e.g.,
  // the following code would not error with `styles: T | NamedStyles<T>`,
  // but would error with `styles: T & NamedStyles<any>`
  styles: T & NamedStyles<any>,
): T {
  return styles;
}

export const getStreamLink = (url: string, option: number) => {
  // const parts = url?.split('/');  // Split the URL by '/'
  // const extension = parts[parts.length - 1];

  const matches = url.match(/\d+/g);
  const id = matches ? matches[matches?.length -1]: undefined;

  if (id) {
    if(option == 2){
      return `https://hd.redditf.xyz/live/channel.php?ch=ch${id}`
    }
    return `https://www.sportsfeed24.xyz/3/live?id=${id}`;
    // return `https://alexsports.lat/HD/${id}.php`;
  } else {
    return undefined
  }

};

// export const getStreamLink = (url: string) => {
//   const parts = url?.split('/');  // Split the URL by '/'
//   const extension = parts[parts.length - 1];

//   if (extension) {
//     // const alex =  `https://alexsports.lat/HD/${extension}`
//     const streamId = extension?.split('.')[0];
//     return `https://www.sportsfeed24.xyz/3/live?id=${streamId}`;
//   } else {
//     return undefined
//   }

// };

export const getFreesportLink = (url: string) => {
  // const parts = url?.split('/');  // Split the URL by '/'
  // const extension = parts[parts.length - 1];

  const matches = url.match(/\d+/g);
  const id = matches ? matches[matches?.length -1]: undefined;

  if (id) {
    // return `https://www.sportsfeed24.xyz/3/live?id=${streamId}`;
    return `https://flstv.online//embed/soccer${id}.php`;
  } else {
    return undefined
  }

};

export const getRedditstreamLink = (url: string) => {
  // const parts = url?.split('/');  // Split the URL by '/'
  // const extension = parts[parts.length - 1];

  const matches = url.match(/\d+/g);
  const id = matches ? matches[matches?.length -1]: undefined;

  if (id) {
    // return `https://www.sportsfeed24.xyz/3/live?id=${streamId}`;
    const extension = url?.includes(`es-${id}`) ? 'es': 'ch';
    return `https://hd.redditf.xyz/live/embed.php?ch=${extension}${id}`;
  } else {
    return undefined
  }

};

export const extractRouteNameFromPath = (path: string) => path.split('/')?.[1] ?? '';
