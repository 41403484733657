import {
  Row,
  Col,
  Card,
  Space,
  Button,
} from 'antd';
import {
  InfoCircleOutlined,
} from '@ant-design/icons';
import './index.css'
import { Link, useParams } from 'react-router-dom';
import { getFixtures } from '../../apis/fixture.api';
import { useEffect, useState } from 'react';
import {CATEGORY_LINKS, HOME_PATH, OTHER_CATEGORIES, PAGE_TITLES, SPORTS_CATEGORIES } from '../../utils/constant';
import Loader from '../../components/common/Loader';
import Title from 'antd/es/typography/Title';
import { styles } from '../../styles';
import { useMediaQuery } from '../../hooks/MediaQuery.hook';
import { getTimer } from '../../utils/timer';
import { Description } from '../Descriptions';

  const cardStyle = {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    height: '100%',
  };

  const headerStyle: any = {
    display: 'flex',
    justifyContent: 'flex-start',
  };

  const iconStyle = {
    color: '#adc6ff',
    marginRight: '10px',
    fontSize: '21px',
    strokeWidth: '15px',
    alignItems: 'middle'
  };

export default  function  Home() {
  const matches = useMediaQuery('(min-width: 1000px)');
  const [fixtures, setFixtures] = useState<any>([]);
  const [noFixtures, setNoFixtures] = useState<boolean>(false);
  const { categoryName } = useParams();
  const [loader, setLoader] = useState<boolean>(false);
  const [url, setUrl] = useState('');

  const getAllFixtures = async ()=> {
    setLoader(true);
    const categoryObj = SPORTS_CATEGORIES.find((cat: any) => cat.label === categoryName);

    const response: any = await getFixtures({ categoryName: categoryObj ? categoryObj.value : categoryName });
    if(!response?.data.length){
      setNoFixtures(true);
    }else{
      setNoFixtures(false);
    }
    setFixtures(response.data);
    setLoader(false);
  }

  function CategoryCard({ category }: any) {
    return (
      <>{category?.matches?.length &&
      <Col style={{ marginBottom: '8px', justifyContent:'center'}} span={24}>
        <Card
          size='small'
          title={
            <div  style={headerStyle}>
              <span style={{display: 'flex', alignItems: 'center', color: 'rgb(152 236 226)', fontSize: '18px', textTransform: 'capitalize'}}>
              
              { category?.categoryImage ? 
              <img style={{width: '30px', paddingRight: '10px'}} 
              src={category?.categoryImage} 
              alt={category?.category} 
              />: <InfoCircleOutlined style={iconStyle} />}
              {category?.category?.toUpperCase()}
              </span>
            </div>
          }
          extra={!categoryName && CATEGORY_LINKS[category?.category?.toUpperCase()] && 
          <Link to={`/streams/${CATEGORY_LINKS[category?.category?.toUpperCase()]}`} 
          style={styles.more} className='more'> More</Link>}
          style={cardStyle}
        >
        <Row gutter={24}>
        {category?.matches?.map((fixture: any, index: any) => (
          <Col span={12} key={index}>
            <FixtureCard fixture={{...fixture, category: category?.category}} />
          </Col>
        ))}
        </Row>

        </Card>
      </Col>}
      </>
    );
  }

  function Timer({ timerValue }: any) {
    return (
      <strong style={timerValue === 'Live' ? { color: 'red' } : {}}>{timerValue}</strong>
    );
  }

  function FixtureCard({ fixture }: any) {
    return (
      <Link 
      to={fixture?.teamB ? '/fixture/' + fixture.teamA + '-vs-' + fixture.teamB: '/fixture/' + fixture.teamA}
      rel="noopener noreferrer"
      >
        <div className='fixture-card'>
          <div className='fixture-link'>
              <div className='flex-display'>
                {matches && <p className='category'>{!OTHER_CATEGORIES.includes(fixture?.category) ? 'Soccer': fixture?.category.toUpperCase()} </p>}
                {matches && <span className='dash'></span>}
                {/* <p className='team-name'><strong> {fixture.teamA}</strong>{' '}</p> */}
                <p className='team-name'>
                  <span>
                  {!matches && <strong>{fixture.teamA}</strong>}

                    <img className='fixture-image' src={fixture.teamAImage} alt={`${fixture?.subCategoryName} - ${fixture.teamA}`}/>
                  </span>
                  {matches && <strong>{fixture.teamA}</strong>}
                </p>
                {fixture?.teamB ? <p className='verses'>vs</p>: ''}
                {/* <p className='team-name'><strong>{fixture.teamB}</strong>{' '}</p> */}
                {fixture?.teamB && <p className='team-name' style={{margin:'0px'}}>
                <strong>{fixture.teamB}</strong>{' '}
                  <span><img className='fixture-image' src={fixture.teamBImage} alt={`${fixture?.subCategoryName} - ${fixture.teamB}`} /></span>
                </p>}
              </div>
          </div>
          <p className='match-time'>
              <Timer timerValue={getTimer(fixture)}></Timer>
          </p>
        </div>
      </Link>
    );
  }

  // const handleButtonClick = () => {
  //   window.open('https://www.buymeacoffee.com/streameast', '_blank');
  // };

  useEffect(() => {
    setUrl(window.location.href);
    document.title = categoryName ? PAGE_TITLES[categoryName?.toUpperCase()]: PAGE_TITLES.WEBSITE_TITLE;
    getAllFixtures();
  }, [categoryName]);

  return (
    <>
      {/* <Teams></Teams> */}
      <Row style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
        <Col span={16} style={{paddingRight: '12px'}}>
          <Row className='flex-display'>
            <Space style={matches ? { marginBottom:'15px'}: { marginBottom: '5px', gap: '2px'}} wrap>
              {/* <Typography.Title level={matches ? 2: 4} style={{color: '#fff'}}>Sports<span style={{color: '#34cfab'}}>Feed</span>24 - Your ultimate destination for free live sports streams.</Typography.Title> */}
              {/* <Button className='time-button' style={{ backgroundColor: '#169C6F' }} shape="round" size={buttonSize}>
                <Link to={DEFAULT_LEAGUE_LINK} >Leagues</Link>
              </Button>
              <Button className='time-button' style={{ backgroundColor: 'rgb(107 104 104)' }} shape="round" size={buttonSize}>
                <Link to={DEFAULT_TEAM_LINK} >Teams</Link>
              </Button>
              <Button className='time-button' style={{ backgroundColor: '#5AB3BB' }} shape="round" size={buttonSize}>
                <Link to={'/leagues/standings'} >Standings</Link>
              </Button>
              <Button onClick={handleButtonClick} className='time-button' style={{ backgroundColor: '#bbc0c4' }} shape="round" size={buttonSize}>
                Donate
              </Button> */}
            </Space>
          </Row>
        </Col>
      </Row>
      <Row gutter={24} style={{display: 'flex', justifyContent: 'center'}}>
        <Col 
        xs={24}  // Full width on mobile screens
        sm={20}   // 20/24 width for small screens (tablets and above)
        md={20}   // 20/24 width for medium screens and up
        lg={20}
        style={{marginTop: '12px'}}>
          {categoryName && noFixtures ? 
          <div>
            <Title level={3} style={{color: '#fff'}}>No Fixtures Found For {categoryName.toUpperCase()}</Title>
            <Title level={3}>
              <Link to={HOME_PATH}><Button style={styles.homeButton}>Home</Button></Link>
            </Title>
          </div>:
           fixtures?.map((category: any, index: any) => (
            <CategoryCard key={index} category={{...category}} />
          ))
            
          }
        </Col>
      </Row>
      <Row gutter={24} style={{display: 'flex', justifyContent: 'center'}}>
        <Col span={matches ? 20: 16}>
        {!loader && !categoryName && <Description url={url}></Description>}
        </Col>
      </Row>
      {loader ? <Loader /> : <></>}
    </>
  );
}
